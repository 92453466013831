import React from 'react';

function Theatre() {
  return (
    <aside id="theatre">
      <div className="container">
        <h2>Théâtre</h2>
     
            <span>
            J'ai fait du théâtre pendant 7 ans avec de l'<strong>écriture, de la mise en scène, du jeu et de l'improvisation</strong>
            </span>
      
      </div>
    </aside>
  );
}

export default Theatre;
